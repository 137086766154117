.container {
  background-color: rgb(223, 241, 255);
  box-shadow: 0 0 10px rgba(34, 50, 50, 0.5);
  border-radius: 10px;
  margin: 50px auto;
  padding: 30px;
  width: 600px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.paragraf {
  width: 60%;
  margin-bottom: -10px;
}

.input {
  width: 60%;
}

@media screen and (max-width: 600px) {
  .container {
    width: 90%;
    min-height: 500px;
    margin: 30px auto;
    padding: 10px;
    border-radius: 10;
  }
  .paragraf {
    width: 90%;
    margin-bottom: -10px;
  }
  .input {
    width: 90%;
    margin: 15px;
  }
}
